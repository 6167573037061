import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ServiceSupportState = {
  selectedGridId: Guid;
  selectedWorkstationId: Guid;
};

const initialState: ServiceSupportState = {
  selectedGridId: "",
  selectedWorkstationId: ""
};

export const serviceSupportSlice = createSlice({
  name: "serviceSupport",
  initialState,
  reducers: {
    setSelectedGridId(state, { payload }: PayloadAction<Guid>) {
      state.selectedGridId = payload;
    },
    setSelectedWorkstationId(state, { payload }: PayloadAction<Guid>) {
      state.selectedWorkstationId = payload;
    }
  }
});
export const { setSelectedGridId, setSelectedWorkstationId } =
  serviceSupportSlice.actions;
