import { Tabs } from "@mui/base/Tabs";
import { Container, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { OutlineTab, OutlineTabsList } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import useFlag from "~/config/flags";
import ApiKeysView from "~/features/apiKeys/ApiKeysView";
import { WorkstationConfig } from "~/features/workstationConfig/WorkstationConfig";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";

import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectIfWorkstationsTabShouldBeEnabled } from "~/redux/selectors/workstationsSelectors";

import { GridSettings } from "./GridSettings";
import { SettingsTabPanel } from "./SettingsTabPanel";
import { BatchSettings } from "./batchSettings/BatchSettings";

type SettingsViewProps = { viewTitle?: ViewNameTranslation };

function SettingsView(props: SettingsViewProps) {
  const { viewTitle } = props;

  const { t } = useTranslation();
  const shouldEnableWorkstationTab = useAppSelector(
    selectIfWorkstationsTabShouldBeEnabled
  );
  const enableApiKeysView = useFlag().enableApiKeysView;
  const isAdmin = useAppSelector(selectUserIsAdmin) || false;
  const shouldDisplayApiKeysTab = isAdmin && enableApiKeysView;

  const { palette } = useTheme();

  useNavbar({ viewTitle });

  const validTabs = [
    "settings",
    ...(shouldEnableWorkstationTab ? ["workstation-config"] : []),
    ...(isAdmin ? ["grid-settings", "batch-settings"] : []),
    ...(shouldDisplayApiKeysTab ? ["api-keys"] : [])
  ];

  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "settings",
    validTabs,
    key: "settings-tab"
  });

  return (
    <Container>
      <Box
        position="sticky"
        width="100%"
        mt={2}
        top={0}
        // z index is set on form controls within the page to 1, so set this
        // to 1 to avoid the controls rendering over top of this sticky panel
        zIndex="1"
        bgcolor={palette.background.default}
      >
        <Tabs
          value={tabIndex}
          onChange={(_event, newValue) =>
            handleTabIndexChange(newValue as string)
          }
          aria-label="settings tabs"
        >
          <OutlineTabsList>
            <OutlineTab value="settings">{t("settings")}</OutlineTab>
            {shouldEnableWorkstationTab && (
              <OutlineTab value="workstation-config">
                {t("workstations")}
              </OutlineTab>
            )}
            {shouldDisplayApiKeysTab && (
              <OutlineTab value="api-keys">{t("api keys")} </OutlineTab>
            )}
            {isAdmin && (
              <OutlineTab value="batch-settings">{`${t("batch")} ${t("nav.viewname.settings")}`}</OutlineTab>
            )}
            {isAdmin && (
              <OutlineTab value="grid-settings">
                {t("grid settings")}
              </OutlineTab>
            )}
          </OutlineTabsList>
        </Tabs>
      </Box>
      <Box bgcolor={palette.background.secondary} pb="4px">
        <Box
          bgcolor={palette.background.default}
          mx="4px"
          borderRadius={tabIndex === "settings" ? "0 8px 8px 8px" : "8px"}
        >
          {tabIndex === "settings" && <SettingsTabPanel />}
          {tabIndex === "workstation-config" && shouldEnableWorkstationTab && (
            <WorkstationConfig />
          )}
          {shouldDisplayApiKeysTab && tabIndex === "api-keys" && (
            <ApiKeysView />
          )}
          {isAdmin && (
            <>
              {tabIndex === "batch-settings" && <BatchSettings />}
              {tabIndex === "grid-settings" && <GridSettings />}
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default SettingsView;
