import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Typography,
  InputLabel,
  Input,
  Stack,
  Button,
  useTheme
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { useGridSelector } from "~/features/inventory/useGridSelector";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectWorkstationAutostoreGridId,
  selectWorkstationId
} from "~/redux/selectors/workstationsSelectors";
import { usePostFlagBinRemovedMutation } from "~/redux/warehouse/autostoreGrid.hooks";

export default function BinRemoved() {
  const { t } = useTranslation();
  const { warningToast, errorToast } = useToast();
  const { palette } = useTheme();
  const { autostoreGridOverride } = useGridSelector();

  const workstationId = useAppSelector(selectWorkstationId);
  const workstationGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const [postFlagBinRemoved, { isLoading: isLoadingPostFlagBinRemoved }] =
    usePostFlagBinRemovedMutation();

  const { register, reset, getValues, watch } = useForm<{
    binId: string;
  }>();

  const handlePostFlagBin = async () => {
    const binId = getValues("binId");
    const gridId = workstationGridId || autostoreGridOverride;
    if (!gridId) {
      return errorToast("Please select an Autostore Grid");
    }
    try {
      await postFlagBinRemoved({
        autostoreGridId: gridId,
        workstationId: workstationId || null,
        binId: +binId
      }).unwrap();
      reset({ binId: "" });
      warningToast(t("bin removed success", { binId }));
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <Stack direction="column" spacing={2} p="50px">
      <Typography variant="h5">{t("bin removed")}</Typography>
      <InputLabel htmlFor="bin-removed-input">
        <Typography>{t("bin removed input label")}</Typography>
      </InputLabel>
      <Input
        sx={{ m: 1 }}
        id="bin-removed-input"
        type="number"
        inputProps={{ min: 0 }}
        {...register("binId")}
      />

      <Button
        onClick={handlePostFlagBin}
        disabled={!watch("binId") || isLoadingPostFlagBinRemoved}
        variant="subtle"
        startIcon={<CheckIcon />}
        sx={{ minHeight: "48px" }}
      >
        {t("confirm")}
      </Button>

      <Stack
        direction="row"
        maxWidth={330}
        justifyContent="center"
        alignItems="center"
        gap="12px"
      >
        <WarningIcon color="warning" />
        <Typography color={palette.warning.main}>
          {t("bin removed warning")}
        </Typography>
      </Stack>
    </Stack>
  );
}
