import { Refresh } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";

import { RelativeTime } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

import { useGetUnifyPortErrorsQuery } from "~/redux/warehouse/unify.openApi";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import { UnifyPortErrors } from "./UnifyPortErrors";
import {
  setSelectedGridId,
  setSelectedWorkstationId
} from "./serviceSupport.slice";

dayjs.extend(relativeTime);

export const UnifyView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const workstation = useAppSelector(selectThisWorkstation);
  const selectedGridId = useAppSelector(
    (state) => state.serviceSupport.selectedGridId
  );
  const selectedWorkstationId = useAppSelector(
    (state) => state.serviceSupport.selectedWorkstationId
  );
  const { sortedWorkstations } = useGetWorkstationsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      sortedWorkstations: data?.toSorted(
        (a, b) =>
          a.autostoreGridName.localeCompare(b.autostoreGridName) ||
          a.deviceId.localeCompare(b.deviceId)
      )
    })
  });

  const {
    fulfilledTimeStamp: portErrorsFulfilledTimeStamp,
    refetch: refetchPortErrors,
    isFetching: isPortErrorsFetching,
    isUninitialized: isPortErrorsUninitialized
  } = useGetUnifyPortErrorsQuery(
    selectedGridId
      ? {
          autostoreGridId: selectedGridId,
          workstationId: selectedWorkstationId,
          limit: null
        }
      : skipToken,
    { pollingInterval: 60000 }
  );

  useEffect(() => {
    if (workstation || autostoreGrids?.length !== 1) return;

    dispatch(setSelectedGridId(autostoreGrids[0].autostoreGridId));
  }, [workstation, autostoreGrids, dispatch]);

  useEffect(() => {
    if (!workstation) return;

    dispatch(setSelectedGridId(workstation.autostoreGridId));
    dispatch(setSelectedWorkstationId(workstation.id));
  }, [dispatch, workstation]);

  return (
    <Stack sx={{ m: 3, gap: 2 }}>
      <Paper
        sx={{
          p: 2
        }}
      >
        <Typography variant="h6">{t("unify")}</Typography>
        <Typography>{t("unify description")}</Typography>
        <Box
          my={2}
          display="grid"
          gridTemplateColumns="repeat(2, minmax(250px, auto)) 1fr"
          gridTemplateRows="repeat(2, auto)"
          gridAutoFlow="column"
          gap={2}
        >
          <FormControl sx={{ gridRow: "span 2" }}>
            <FormLabel id="grid-select">{t("autostore grid")}</FormLabel>
            <Select
              labelId="grid-select"
              variant="outlined"
              value={selectedGridId ?? ""}
              onChange={(e: SelectChangeEvent) =>
                dispatch(setSelectedGridId(e.target.value))
              }
              size="medium"
              displayEmpty
            >
              <MenuItem value="">{t("none")}</MenuItem>
              {autostoreGrids?.map((grid) => (
                <MenuItem
                  key={grid.autostoreGridId}
                  value={grid.autostoreGridId}
                >
                  {`${grid.autostoreGridName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ gridRow: "span 2" }}>
            <FormLabel id="workstation-select">{t("workstation")}</FormLabel>
            <Select
              labelId="workstation-select"
              variant="outlined"
              value={sortedWorkstations ? selectedWorkstationId : ""}
              onChange={(e: SelectChangeEvent) =>
                dispatch(setSelectedWorkstationId(e.target.value))
              }
              size="medium"
              displayEmpty
            >
              <MenuItem value="">
                <em>{t("all")}</em>
              </MenuItem>
              {sortedWorkstations?.map((workstation) => (
                <MenuItem key={workstation.deviceId} value={workstation.id}>
                  {`${workstation.deviceId}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            justifySelf="right"
          >
            {!isPortErrorsUninitialized && (
              <>
                <Typography>{t("last updated")}: </Typography>
                <RelativeTime time={portErrorsFulfilledTimeStamp} />
                <Button
                  startIcon={<Refresh />}
                  variant="subtle"
                  disabled={isPortErrorsFetching}
                  onClick={refetchPortErrors}
                >
                  {t("refresh")}
                </Button>
              </>
            )}
          </Stack>
        </Box>
        {!!autostoreGrids && !selectedGridId && (
          <Alert sx={{ mb: 2 }} severity="info">
            {t("no autostore grid selected")}
          </Alert>
        )}
        <UnifyPortErrors />
      </Paper>
    </Stack>
  );
};
