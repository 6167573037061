import { Components, Theme } from "@mui/material";

export const muiFormControl: Components<Omit<Theme, "components">> = {
  MuiFormControl: {
    defaultProps: {
      // the "standard" form control places the label above the input, and the FormHelperText
      // will render without a 14px left & right margin
      variant: "standard"
    }
  }
};
