import { Button, Typography, Box, Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { useToast } from "@qubit/autoparts";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { BinFlag } from "~/api/warehouseTypes/bin";
import { useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";

import { StoreState } from "~/redux/reducers";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { useDeleteBinFlagMutation } from "~/redux/warehouse/autostoreGrid.hooks";
import { GetBinResponse } from "~/types/api";

const mapStateToProps = (state: StoreState) => ({
  siteWorkstation: state.workstations.siteWorkstation
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;
type CleaningBinModalProps = {
  open: boolean;
  closeModal: () => void;
  selectedBinInfo: GetBinResponse | undefined;
};

function CleaningBinModal(props: CleaningBinModalProps & PropsFromRedux) {
  const { successToast } = useToast();
  const { open, closeModal, selectedBinInfo } = props;

  const { t } = useTranslation();
  const workstationAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const [deleteBinFlag] = useDeleteBinFlagMutation();

  const cleanBin = async () => {
    if (!workstationAutostoreGridId || !selectedBinInfo) return;

    const { binState, flags } = selectedBinInfo;
    for (const reason of flags) {
      await deleteBinFlag({
        autostoreGridId: workstationAutostoreGridId,
        binNumber: binState.binId,
        reason,
        workstationId: siteWorkstation?.id
      });
    }
    closeModal();
    successToast("Bin Cleaned", {
      description: `Bin ${binState.binId} has successfully been marked as clean.`
    });
  };

  return (
    <Paper sx={{ flexGrow: 1 }}>
      <Dialog open={open} onClose={closeModal} fullWidth>
        <DialogTitle>{t("bin maintenance")}</DialogTitle>
        <Divider />
        <Box style={{ margin: "5px 10px" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={4}>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "primary.dark",
                  padding: "5px"
                }}
              >
                {t("bin flag")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "primary.dark",
                  padding: "5px"
                }}
              >
                {t("bin id") + " "}
                {selectedBinInfo?.binState.binId}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "primary.dark",
                  fontWeight: "bold",
                  padding: "5px"
                }}
              >
                {selectedBinInfo?.flags && selectedBinInfo.flags.length > 0
                  ? selectedBinInfo.flags
                      .map((flag) => t(flag as BinFlag))
                      .join(", ")
                  : t("bin already cleaned")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <AutostoreBin
                state={"Port Closed"}
                pickQuantity={0}
                pickCompartment={null}
                numberOfColumns={
                  selectedBinInfo?.binConfiguration?.verticalCompartmentCount ||
                  1
                }
                numberOfRows={
                  selectedBinInfo?.binConfiguration
                    ?.horizontalCompartmentCount || 1
                }
                hideBinId
              />
            </Grid>
          </Grid>
        </Box>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button variant="contained" autoFocus onClick={cleanBin}>
            {t("cleaning complete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default connector(CleaningBinModal);
