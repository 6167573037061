import { Typography, TypographyProps } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";

dayjs.extend(relativeTime);

export type RelativeTimeProps = {
  time: dayjs.ConfigType;
  interval?: number;
};

/**
 * A component that renders a relative time string from now that updates on an interval.
 */
export const RelativeTime = ({
  time,
  interval,
  ...rest
}: TypographyProps & RelativeTimeProps) => {
  const [ownTime, setOwnTime] = useState(dayjs(time).fromNow());
  useEffect(() => {
    const windowInterval = setInterval(() => {
      setOwnTime(dayjs(time).fromNow());
    }, interval ?? 30000);
    return () => clearInterval(windowInterval);
  }, [time, interval]);

  return <Typography {...rest}>{ownTime}</Typography>;
};
