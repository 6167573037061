import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { buttonClasses, dividerClasses, Divider } from "@mui/material";

import { ComponentProps } from "react";

import { basicColours, mainColours } from "../theme/colours";
import { styled } from "../theme/theme";

const ContainedStyledTab = styled(BaseTab)(({ theme }) => {
  return {
    color: theme.palette.text.secondary,
    cursor: "pointer",

    border: "none",
    borderColor: "none",
    borderRadius: "8px",

    backgroundColor: basicColours.mono[100],
    display: "flex",
    height: "40px",
    width: "220px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",

    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontWeight: 400,

    "&:hover": {
      backgroundColor: basicColours.mono[50],
      color: mainColours.text.inactiveHover
    },

    "&:focus-visible": {
      backgroundColor: theme.palette.primary.light,
      outline: "none"
    },

    [`&.${tabClasses.selected}`]: {
      backgroundColor: theme.palette.background.default,
      boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.16)",
      color: theme.palette.text.primary
    },

    [`&.${tabClasses.selected}:focus-visible`]: {
      backgroundColor: theme.palette.primary.light,
      outline: "none"
    },

    [`&.${buttonClasses.disabled}`]: {
      opacity: "0.5",
      cursor: "not-allowed"
    }
  };
});

export const ContainedTabDivider = styled(Divider)(({ theme }) => {
  return {
    height: "32px",
    alignSelf: "center",
    borderLeft: `2px solid ${theme.palette.background.hover}`
  };
});

export function ContainedTab(props: ComponentProps<typeof ContainedStyledTab>) {
  return (
    <>
      <ContainedStyledTab {...props} />
      <ContainedTabDivider role="none" />
    </>
  );
}

export const ContainedTabPanel = styled(BaseTabPanel)(({ theme }) => {
  return {
    width: "100%",
    background: theme.palette.background.default
  };
});

export const ContainedTabsList = styled(BaseTabsList)({
  backgroundColor: basicColours.mono[100],
  padding: "4px",
  display: "flex",
  borderRadius: "10px",

  // last inactive tab divider
  [`.${dividerClasses.root}:last-of-type`]: {
    display: "none"
  },
  // divider after active tab
  [`.${dividerClasses.root}:has(+ .${tabClasses.selected})`]: {
    visibility: "hidden"
  },
  // divider after active tab
  [`.${tabClasses.selected} + .${dividerClasses.root}`]: {
    visibility: "hidden"
  }
});
