import { warehouseService } from "~/api/warehouse";
import { AsyncAppThunk } from "~/app/store";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import { UserEventDto, WorkstationDto } from "~/types/api";

export type TaskType = "PICKING" | "CYCLECOUNT" | "INDUCTION" | "CYCLECOUNTV2";

const userTaskSelected = "UserTaskSelected";
type EventType = "UserLoggedOut" | "UserLoggedIn";

export const sendUserTaskSelectedEvent =
  (taskType: TaskType): AsyncAppThunk =>
  async (_dispatch, getState) => {
    const store = getState();
    const userId =
      store.login.profile?.sub ?? store.login.profile?.userId ?? null;
    const clientId = selectUsersClientId(store) || "";
    const fulfillmentCenterId = store.workstations.siteFulfillmentCenterId;
    const gridId: unknown =
      (store.workstations.siteWorkstation || {}).autostoreGridId || "";
    const workstationDeviceId: unknown =
      (store.workstations.siteWorkstation || {}).deviceId || "";
    await warehouseService.post("/pepsi/api/v1/user/event", {
      Type: userTaskSelected,
      Data: {
        UserId: userId,
        // Deprecated
        WorkstationId: workstationDeviceId,
        WorkstationName: workstationDeviceId,
        AutoStoreGridId: gridId,
        FulfillmentCenterId: fulfillmentCenterId,
        ClientId: clientId,
        TaskType: taskType
      }
    });
  };

export const callEventEndpoint = async (
  userId: string | null,
  clientId: string | null,
  workstation: WorkstationDto | null,
  type: EventType,
  // temporary param until bev client logout is extracted from this call
  // only emitting events, prevents actual logout from happening and merely
  // sends those events to the event feed
  workstationFCId: Guid | null,
  onlyEmitEvents = false
) => {
  const fulfillmentCenterId = workstationFCId;
  const gridId: string = (workstation || {}).autostoreGridId || "";
  const workstationId: Guid =
    (workstation || {}).id || "00000000-0000-0000-0000-000000000000";
  const workstationDeviceId: string = (workstation || {}).deviceId || "";

  // bev client has client-specific url
  const endpoint =
    clientId === "9e951c04-edad-42a7-af77-accaf41701a1" &&
    type === "UserLoggedOut" &&
    !onlyEmitEvents
      ? "/pepsi/api/v1/user/event"
      : "/api/users/event";

  try {
    await warehouseService.post<UserEventDto>(
      endpoint,
      {
        Type: type,
        Data: {
          UserId: userId,
          // Deprecated
          WorkstationId: workstationDeviceId,
          WorkstationName: workstationDeviceId,
          WorkstationGuid: workstationId,
          AutoStoreGridId: gridId,
          FulfillmentCenterId: workstationFCId,
          ClientId: clientId
        }
      },
      { params: { fulfillmentCenterId } }
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const sendUserEventAction =
  (type: EventType): AsyncAppThunk =>
  async (_dispatch, getState) => {
    const store = getState();
    const userId =
      store.login.profile?.sub ?? store.login.profile?.userId ?? null;
    const clientId = selectUsersClientId(store);
    const workstation = store.workstations.siteWorkstation;
    const workstationFulfillmentCenterId =
      store.workstations.siteFulfillmentCenterId;

    await callEventEndpoint(
      userId,
      clientId,
      workstation,
      type,
      workstationFulfillmentCenterId
    );
  };
