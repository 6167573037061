import { createSvgIcon } from "@mui/material";

export const CloseSmall20pxIcon = createSvgIcon(
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="close-small-20px">
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50015 6.81348L10.0002 9.31348L12.5002 6.81348L13.9144 8.22769L11.4144 10.7277L13.9144 13.2277L12.5002 14.6419L10.0002 12.1419L7.50015 14.6419L6.08594 13.2277L8.58594 10.7277L6.08594 8.22769L7.50015 6.81348Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  "CloseSmall20px"
);
