import AutomaticIcon from "@locaisolutions/icons/dist/icons20px/Automatic20Px";
import {
  Button,
  Stack,
  Grid,
  Alert,
  FormLabel,
  FormControl
} from "@mui/material";
import { ConfirmationModal } from "@qubit/autoparts";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import envConstants from "~/config/envConstants";
import { useGridSelector } from "~/features/inventory/useGridSelector";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import {
  useGetAutostoreGridsQuery,
  useLazySyncGridStateQuery
} from "~/redux/warehouse/autostoreGrid.hooks";

/**
 * Renders a dropdown to choose a grid, and a button to synchronize the state of the grid against the log publisher.
 *
 * Users may execute this during the initial setup of a new grid in Qubit.
 * @constructor
 */
export function GridStateSynchronizer() {
  const { t } = useTranslation();
  const [
    syncGridState,
    {
      isFetching: syncGridStateLoading,
      error: syncGridStateError,
      data: syncGridStateData
    }
  ] = useLazySyncGridStateQuery();
  const { data: autostoreGrids } = useGetAutostoreGridsQuery(undefined);

  const [isConfirmationModalOpen, setConfirmationModalVisibilityStatus] =
    useState(false);

  const isAdmin = useAppSelector(selectUserIsAdmin);

  const { autostoreGridOverride: selectedAutostoreGridId, gridSelectorButton } =
    useGridSelector({ id: "grid-selector" });

  const selectedAutostoreGridName = useMemo(() => {
    return (
      autostoreGrids?.find(
        (el) => el.autostoreGridId === selectedAutostoreGridId
      )?.autostoreGridName || ""
    );
  }, [autostoreGrids, selectedAutostoreGridId]);

  const handleSyncGridState = async () => {
    if (!selectedAutostoreGridId) {
      return;
    }
    setConfirmationModalVisibilityStatus(true);

    await syncGridState({
      gridId: selectedAutostoreGridId
    });
  };
  const syncGridDataModalText = syncGridStateLoading
    ? t("syncing grid state in progress")
    : syncGridStateError
      ? t("sync grid state error")
      : syncGridStateData;

  return (
    <>
      {isAdmin &&
        envConstants.SHOW_SYNC_GRID_STATE_BUTTON === "true" &&
        !!autostoreGrids?.length && (
          <Stack
            data-testid="sync-grid-button-spacer"
            direction="row"
            gap={1}
            alignItems="flex-end"
          >
            <FormControl>
              <FormLabel htmlFor="grid-selector">
                {`${t("autostore grid")}`}
              </FormLabel>
              {gridSelectorButton}
            </FormControl>
            <Button
              variant="subtle"
              startIcon={<AutomaticIcon fill="currentColor" />}
              onClick={handleSyncGridState}
              disabled={!selectedAutostoreGridId}
            >
              {t("sync grid state")}
            </Button>
          </Stack>
        )}
      {syncGridStateError && !syncGridStateLoading && (
        <Grid item xs={6} sx={{ margin: "15px 10px 5px 10px" }}>
          <Alert
            variant="outlined"
            severity="error"
            action={<Button onClick={handleSyncGridState}>{t("retry")}</Button>}
          >
            {t("sync grid state")}: {getMessageFromRtkError(syncGridStateError)}
          </Alert>
        </Grid>
      )}
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        confirmCb={() => {
          setConfirmationModalVisibilityStatus(false);
        }}
        closeCb={() => {
          setConfirmationModalVisibilityStatus(false);
        }}
        modalTitle={
          selectedAutostoreGridName
            ? `${t("sync grid state")} - ${selectedAutostoreGridName}`
            : t("sync grid state")
        }
        modalText={syncGridDataModalText}
        noCancelButton
        loader={syncGridStateLoading}
        disableConfirmButton={syncGridStateLoading}
        confirmText={t("close")}
      />
    </>
  );
}
