import axios from "axios";
import { stringify } from "qs";

import envConstants from "~/config/envConstants";
import { accessTokenFactory } from "~/features/login/login.slice";
import { AuthMethod } from "~/features/login/login.slice.interfaces";
import { processErrorArray } from "~/lib/helpers";

import { isAxiosError } from "./shared";

let interceptorId: number | null = null;

export const getWarehouseServiceUrl = (): string => {
  const isLocalHost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1";

  if (window.location.hostname.indexOf("pepsico") > -1) {
    return envConstants.PEPSI_WAREHOUSE_API;
  }
  if (window.location.hostname.indexOf("autostoresystem") > -1 || isLocalHost) {
    return envConstants.AS_WAREHOUSE_API;
  }
  return envConstants.WAREHOUSE_API;
};

export const warehouseService = axios.create({
  baseURL: getWarehouseServiceUrl(),
  headers: {},
  params: {},
  paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
});

export function setupAuthInterceptor({
  authMethod,
  stateAccessToken
}: {
  authMethod: AuthMethod | null;
  stateAccessToken: string | null;
}): void {
  // remove previous interceptor created by this function
  // axios service will convert the former interceptor to null
  if (interceptorId !== null) {
    warehouseService.interceptors.request.eject(interceptorId);
  }

  interceptorId = warehouseService.interceptors.request.use(
    async (config) => {
      try {
        const token = await accessTokenFactory(stateAccessToken, authMethod);

        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      } catch {
        // proceed without attaching token if there is an error attaching token to request
        return config;
      }
    },
    (error) => {
      // error setting up interceptor
      return Promise.reject(error);
    }
  );
}

export function handleWarehouseError(
  err: unknown,
  dispatch: (message: string) => void
): void {
  if (isAxiosError<string | string[]>(err)) {
    const { message = "Something Went Wrong!" } = err;
    const responseMessage = err?.response?.data;
    const stringMessage = Array.isArray(responseMessage)
      ? processErrorArray(responseMessage)
      : responseMessage;
    dispatch(stringMessage || message);
  } else if (err instanceof Error) {
    const { message } = err;
    dispatch(message);
  }

  throw err;
}

export function handleWarehouseErrorAsyncThunk(err: unknown): string {
  if (isAxiosError<string | string[]>(err)) {
    const { message = "Something Went Wrong!" } = err;
    const responseMessage = err?.response?.data;
    const stringMessage = Array.isArray(responseMessage)
      ? processErrorArray(responseMessage)
      : responseMessage;
    return stringMessage || message;
  }
  if (err instanceof Error) {
    const { message } = err;
    return message;
  }

  throw err;
}
