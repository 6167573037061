import { useToast } from "@qubit/autoparts";
import { useCallback } from "react";

import { useAppSelector } from "~/app/store";
import useFlag from "~/config/flags";
import { useUnifySubscription } from "~/lib/signalr";
import {
  selectThisWorkstation,
  selectWorkstationAllPorts
} from "~/redux/selectors/workstationsSelectors";

import { UnifyEvent } from "~/types/api";

export const useUnifyConnect = () => {
  const { enableUnifyConnectToasts } = useFlag();
  const { warningToast } = useToast();

  const workstation = useAppSelector(selectThisWorkstation);
  const allWorkstationPorts = useAppSelector(selectWorkstationAllPorts);
  const handleUnifyEvent = useCallback(
    (event: UnifyEvent) => {
      if (
        !enableUnifyConnectToasts ||
        !workstation ||
        event.case !== "PortErrorV1" ||
        event.item.gridId !== workstation.autostoreGridId ||
        !allWorkstationPorts.includes(event.item.portId) ||
        (event.item.multiPortId &&
          !allWorkstationPorts.includes(event.item.multiPortId))
      )
        return;

      const targetPortId = event.item.multiPortId ?? event.item.portId;

      if (event.item.portError === "OVERFILLED_BIN_DETECTED") {
        warningToast(`Overfilled bin detected at port ${targetPortId}`);
      }
      if (event.item.portError === "ESTOP_SWITCH") {
        warningToast(`EStop button was hit on port ${targetPortId}`);
      }
    },
    [allWorkstationPorts, enableUnifyConnectToasts, warningToast, workstation]
  );

  useUnifySubscription(handleUnifyEvent);
};
