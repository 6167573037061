import { AxiosError, AxiosInstance } from "axios";

import { AppDispatch } from "~/app/store";
import { setUnauthorizedLogoutRequested } from "~/features/login/login.slice";

export function applyRedirectOn401Response(
  axiosInstance: AxiosInstance,
  dispatch: AppDispatch
): void {
  // configure axios to redirect to the login screen if an auth error occurs
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error: { response?: { status: number; config: { url: string } } }) => {
      if (
        error.response?.status === 401 &&
        error.response?.config?.url !== "/pepsi/api/v1/user/login" &&
        // don't infinitely redirect
        !window.location.href.includes("/logout") &&
        !window.location.href.includes("/login")
      ) {
        // navigate to the logout route, which will handle redux cleanup and navigation to /login for us
        dispatch(setUnauthorizedLogoutRequested(true));
      }
      return Promise.reject(error);
    }
  );
}

export function isAxiosError<T>(error: unknown): error is AxiosError<T> {
  return !!error && (error as AxiosError<T>).isAxiosError;
}
