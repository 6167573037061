import { LoadingPage, useToast } from "@qubit/autoparts";
import mixpanel from "mixpanel-browser";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import envConstants from "~/config/envConstants";
import {
  clearFulfillmentCenter,
  fetchFulfillmentCenter
} from "~/redux/actions/store";
import { sendUserEventAction } from "~/redux/actions/userEvents";
import { selectAuth0UserId } from "~/redux/selectors/authSelectors";
import { selectManualOpsEnabled } from "~/redux/selectors/siteSelectors";
import { useLazyGetAllFulfillmentCentersQuery } from "~/redux/warehouse/fulfillmentCenter.hooks";

import { handleAuthCallback } from "./login.slice";

const connector = connect(null, {
  fetchFulfillmentCenter,
  clearFulfillmentCenter,
  sendUserEventAction
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type AuthCallBackProps = PropsFromRedux;

function AuthCallback(props: AuthCallBackProps) {
  const {
    fetchFulfillmentCenter,
    clearFulfillmentCenter,
    sendUserEventAction
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const { t } = useTranslation();

  const [getAllFulfillmentCenters] = useLazyGetAllFulfillmentCentersQuery();

  const siteFulfillmentCenterId = useAppSelector(
    (state) => state.workstations.siteFulfillmentCenterId
  );
  const loginPath = useAppSelector((state) => state.site.loginPath);
  const loginPathToUse: string = loginPath || "/login";
  const manualOpsEnabled = useAppSelector(selectManualOpsEnabled);

  const currentUserId = useAppSelector(selectAuth0UserId);
  useEffect(() => {
    if (envConstants.RUNTIME_ENVIRONMENT !== "production") return;
    if (currentUserId) mixpanel.identify(currentUserId);
  }, [currentUserId]);

  const authCallBackComplete = useRef(false);

  useEffect(() => {
    if (authCallBackComplete.current) return;
    authCallBackComplete.current = true;

    const handleSuccess = async () => {
      try {
        await sendUserEventAction("UserLoggedIn");
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_err) {
        errorToast("Failed to send user event action");
      }

      // siteFulfillmentCenter is set when user has selected one in a previous sesssion
      // validate the fc id and refetch the fc, in case it has changed
      try {
        const clientFcs = await getAllFulfillmentCenters().unwrap();
        const clientFcIds = clientFcs.map((fc) => fc.fulfillmentCenterId) || [];

        if (
          siteFulfillmentCenterId &&
          clientFcIds.includes(siteFulfillmentCenterId)
        ) {
          // hits `/api/fulfillment-centers/${fulfillmentCenterId}`
          // puts the user's fc in the store
          // puts the appropriate batch filters into the batch store, used throughout the app
          await fetchFulfillmentCenter(siteFulfillmentCenterId);
        } else {
          // clears the fc center and batch filters
          clearFulfillmentCenter();
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_err) {
        clearFulfillmentCenter();
      }

      // redirect the user to manual ops if enabled,
      // otherwise to the autostore main page
      const nextPage = manualOpsEnabled ? "/" : "/autostore-main";
      navigate(nextPage);
    };

    const handleError = () => {
      navigate(loginPathToUse);
    };

    const performAuthCallback = async () => {
      const resultAction = await dispatch(handleAuthCallback());

      if (handleAuthCallback.fulfilled.match(resultAction)) {
        await handleSuccess();
      } else {
        handleError();
      }
    };

    void performAuthCallback();
  }, [
    clearFulfillmentCenter,
    dispatch,
    fetchFulfillmentCenter,
    siteFulfillmentCenterId,
    getAllFulfillmentCenters,
    errorToast,
    navigate,
    sendUserEventAction,
    loginPathToUse,
    manualOpsEnabled
  ]);

  // render an invisible backdrop to prevent any interactivity while logging on
  return <LoadingPage loadingMessage={t("logging on")} />;
}

export default connector(AuthCallback);
