import { Button, Typography, Box, Grid, Paper, Stack } from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { BinFlag } from "~/api/warehouseTypes/bin";
import { useAppDispatch, useAppSelector } from "~/app/store";
import envConstants from "~/config/envConstants";
import { AutostoreBin } from "~/features/autostoreBin";
import { AutostoreBinConfigModal } from "~/features/autostoreBinConfigModal/AutostoreBinConfigModal";
import { openBinConfigModal } from "~/features/autostoreBinConfigModal/autostoreBinConfigModal.slice";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { getBarcodeValue } from "~/lib/helpers";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { StoreState } from "~/redux/reducers";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import {
  useLazyGetBinQuery,
  useReconfigureBinCompartmentsMutation,
  useStartBinCleaningMutation
} from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetBinConfigurationsQuery } from "~/redux/warehouse/bin.hooks";

import { BinFilter } from "./BinFilter";
import CleaningBinModal from "./CleaningBinModal";
import { CleaningBinTable } from "./CleaningBinTable";
import { ConveyanceSimulator } from "./ConveyanceSimulator";

const mapStateToProps = (state: StoreState) => ({
  siteWorkstation: state.workstations.siteWorkstation,
  showConveyanceSim: state.conveyance.conveyanceSimEnabled
});

const connector = connect(mapStateToProps);
type BinCleaningInheritedProps = { viewTitle?: ViewNameTranslation };
type PropsFromRedux = ConnectedProps<typeof connector>;
type BinCleaningProps = PropsFromRedux & BinCleaningInheritedProps;

function CleaningBin(props: BinCleaningProps) {
  const { siteWorkstation, viewTitle, showConveyanceSim } = props;
  const siteWorkstationId = siteWorkstation?.id;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successToast, errorToast, warningToast } = useToast();

  useNavbar({
    centerComponent: useMemo(() => <BinFilter />, []),
    viewTitle
  });

  const [openCleaningBinModal, setCleaningBinModalOpen] = useState(false);
  const [scannedBinNumber, setScannedBinNumber] = useState<number>();

  const workstationAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const [startBinCleaning] = useStartBinCleaningMutation();
  const [reconfigureBinCompartments] = useReconfigureBinCompartmentsMutation();
  const [fetchBinInfo, { data: scannedBinInfo }] = useLazyGetBinQuery();
  const { data: availableBinConfigurations } = useGetBinConfigurationsQuery();

  // run on page mount
  useEffect(() => {
    if (!workstationAutostoreGridId || !siteWorkstationId) return;
    const callStartBinCleaning = async () =>
      await startBinCleaning({
        autostoreGridId: workstationAutostoreGridId,
        workstationId: siteWorkstationId
      }).unwrap();

    callStartBinCleaning()
      .then((resp) => {
        const { areBinsToClean, areInventoryMovements } = resp;
        if (!areBinsToClean) {
          if (areInventoryMovements)
            warningToast(t("move inventory before bin cleaning"));
          else successToast(t("no bins for cleaning"));
        }
      })
      .catch((err) => {
        errorToast(getMessageFromRtkError(err));
      });
  }, [
    siteWorkstationId,
    startBinCleaning,
    workstationAutostoreGridId,
    successToast,
    warningToast,
    errorToast,
    t
  ]);

  const handleScanBin = (scannedBinNumber: number) => {
    if (!workstationAutostoreGridId || !scannedBinNumber) return;

    fetchBinInfo({
      autostoreGridId: workstationAutostoreGridId,
      binNumber: scannedBinNumber
    })
      .unwrap()
      .then(() => {
        setScannedBinNumber(scannedBinNumber);
        successToast(
          `Successfully scanned bin for bin number: ${scannedBinNumber}`
        );
      })
      .catch(() =>
        errorToast(`No bin found for bin number: ${scannedBinNumber}`)
      );
  };

  useKeyDownHandler();
  useBarcodeScanner<boolean>({
    findScanMatch: (initialBuffer: string) => {
      const buffer: string = getBarcodeValue(initialBuffer);
      handleScanBin(Number(buffer));
      return true;
    },
    processScanMatch: () => true
  });

  const handleReconfigureCompartments = async (
    selectedBinConfigurationTypes: number[]
  ) => {
    if (!selectedBinConfigurationTypes.length) return;
    const selectedBinConfiguration = availableBinConfigurations?.find(
      (bc) => bc.configurationType === selectedBinConfigurationTypes[0]
    );

    if (
      !workstationAutostoreGridId ||
      !scannedBinInfo ||
      !selectedBinConfiguration
    ) {
      return;
    }
    try {
      await reconfigureBinCompartments({
        autostoreGridId: workstationAutostoreGridId,
        binNumber: scannedBinInfo.binState.binId,
        configurationId: selectedBinConfiguration.configurationId,
        workstationId: siteWorkstation?.id
      }).unwrap();

      if (scannedBinNumber) {
        await fetchBinInfo({
          autostoreGridId: workstationAutostoreGridId,
          binNumber: scannedBinNumber
        }).unwrap();
      }

      successToast(
        `Successfully reconfigured bin to have ${selectedBinConfiguration.numberOfCompartments} compartments`
      );
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  return (
    <Box>
      <Grid container spacing={2} p={2}>
        <Grid item xs={4}>
          <Paper
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              gap: 3,
              alignItems: "center"
            }}
          >
            <Stack
              flexDirection="row"
              justifyContent="space-around"
              alignSelf="stretch"
            >
              <Typography variant="h6">
                {t("bin id") + ": "}
                {scannedBinNumber ? scannedBinInfo?.binState.binId : ""}
              </Typography>
              <Typography variant="h6">
                {t("bin flag")}{" "}
                {scannedBinInfo?.flags && scannedBinInfo.flags.length > 0
                  ? scannedBinInfo.flags
                      .map((flag) => t(flag.toLowerCase() as BinFlag))
                      .join(", ")
                  : ""}
              </Typography>
            </Stack>

            <AutostoreBin
              state={!scannedBinNumber ? "Waiting for Bin" : "Bin Opened"}
              pickQuantity={0}
              pickCompartment={null}
              numberOfColumns={
                (scannedBinNumber &&
                  scannedBinInfo?.binConfiguration?.verticalCompartmentCount) ||
                1
              }
              numberOfRows={
                (scannedBinNumber &&
                  scannedBinInfo?.binConfiguration
                    ?.horizontalCompartmentCount) ||
                1
              }
              binId={scannedBinInfo?.binState.binId}
              hideBinId
            />

            <Stack
              flexDirection="row"
              justifyContent="space-around"
              alignSelf="stretch"
              bgcolor="#fff"
            >
              <Button
                variant="subtle"
                onClick={() => dispatch(openBinConfigModal())}
                disabled={!scannedBinNumber}
              >
                {t("reconfiguration")}
              </Button>
              <Button
                variant="subtle"
                disabled={!scannedBinNumber}
                onClick={() => {
                  setCleaningBinModalOpen(true);
                }}
              >
                {t("unflag bin")}
              </Button>
            </Stack>
          </Paper>
          {envConstants.ENABLE_CONVEYANCE_SIMULATION === "true" &&
            showConveyanceSim && <ConveyanceSimulator />}
          <AutostoreBinConfigModal
            isOneSelection
            currentBinConfigurations={
              scannedBinInfo?.binConfiguration?.configurationType
                ? [scannedBinInfo.binConfiguration.configurationType]
                : []
            }
            saveBinConfigurations={handleReconfigureCompartments}
          />
        </Grid>
        <Grid item xs={8} height={"calc(100vh - 128px)"}>
          <CleaningBinTable />
        </Grid>
      </Grid>
      {openCleaningBinModal && (
        <CleaningBinModal
          closeModal={() => {
            setCleaningBinModalOpen(false);
            setScannedBinNumber(undefined);
          }}
          open={openCleaningBinModal}
          selectedBinInfo={scannedBinInfo}
        />
      )}
    </Box>
  );
}
export default connector(CleaningBin);
